module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1170,"quality":90,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.conjurersolutions.co.uk"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics-gdpr/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-158971918-1","enableDevelopment":false,"anonymizeIP":true,"autoStartWithCookiesEnabled":false,"reactGaOptions":{"debug":false,"gaOptions":{"sampleRate":10}}},
    }]
